// If you don't want to use TypeScript you can delete this file!
import * as React from "react"
import { PageProps, Link, graphql,navigate } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import "../assets/home.css";
import piaiclogo from "../assets/LogoPIAIC.svg";

import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

type DataProps = {
    allMongodbPiaictestStudentQuarterResults: {
        group: {
            edges:{
                node: {
                    course: string,
                    id:string,
                    grade:string,
                    rollNo:string,
                    quarter:string,
                    mongodb_id:string,
                    batch:string,
                    isPassed:boolean,
                    testsMissed:boolean,
                    rank:boolean,
                    percentile:boolean
                }
            }[]
            totalCount:Number
        }[]
    }
}
type useRefType={
  value:string
}
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={12} ref={ref} variant="filled" {...props} />;
});
const Home: React.FC<PageProps<DataProps>> = ({ data, path }) => {
    const [open, setOpen] = React.useState(false);
    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpen(false);
    };
    const getRollNumber=React.useRef<useRefType>()
    const onSubm=()=>{
      if(getRollNumber.current && getRollNumber.current.value.match("^[(AIC)]{3}|[(aic)]{3}|[(IOT)]{3}|[(iot)]{3}|[(CNC)]{3}|[(cnc)]{3}|[(PIAIC)]{3}|[(piaic)]{3}|[(BCC)]{3}|[(bcc)]{3}[0-9]")){
        navigate('/'+getRollNumber.current.value.toUpperCase());
      }else{
        setOpen(true);
      }
    }
    const alertOrign={vertical: 'bottom', horizontal: 'left'}
    return <Layout>
        <Seo title="Certification @ PIAIC" description="PIAIC is an interdisciplinary hub for mass education, research, and business in artificial intelligence (AI), data science, cloud computing, edge computing, blockchain and internet of things (IoT) related fields. As AI and computing reshape our world, PIAIC intends to help make sure that Pakistan plays its part in this fourth industrial revolution. PIAIC aims is to transform education, research and business in Pakistan." />
        <div className="AdminLogin-main-div d-flex justify-content-center align-items-center">
        <div className="AdminLogin-form-div">
          <div className="AdminLogin-head">
            <span className="AdminLogin-head-text">Certification Area</span>
          </div>
          <img
            src={piaiclogo}
            alt="PIAIC Logo"
            title="Presidential Initiative for Artificial Intelligence and Computing"
            className="AdminLogin-m-r-l AdminLogin-display-block AdminLogin-logo"
          />
          <p className="AdminLogin-piaic-text">
            Presidential Initiative for <br /> Artificial Intelligence and
            Computing
          </p>
            <div className="AdminLogin-form">
              <TextField  inputRef={getRollNumber} fullWidth id="outlined-basic" placeholder="Enter Your Roll Number" style={{backgroundColor:"#ffff",borderRadius:"100px"}}/>
                <button onClick={onSubm} type="submit" className="AdminLogin-submit-button AdminLogin-m-r-l">
                  Search
                </button>
            </div>
          <br />
          <Snackbar open={open} autoHideDuration={6000} anchorOrigin={{ vertical: "bottom", horizontal: "center" }} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
              Please Enter Valid Number i.e AICxxxxxx | IOTxxxxxx | CNCxxxxxx | PIAICxxxxxx | BCCxxxxxx
            </Alert>
          </Snackbar>
        </div>
      </div>
    </Layout>
}
export default Home
